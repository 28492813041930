.privatlivspolitik-main-layout{
    margin: 5rem 25rem 5rem 5rem;
}

.title-margin-politiks{
    margin: 2rem 0;
}

.content-margin-politiks{
    margin: 1rem 0;
    line-height: 2rem;
}