.company-table-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.region-filter{
  width: 80%;
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: "Roboto", sans-serif;
}

input[type="checkbox"] {
  border-color: var(--darkblue);
  margin-left: 1rem;
}

input[type="checkbox"]:checked {
  accent-color: var(--darkblue);
}

.company-table {
  width: 80%;
  border-collapse: collapse;
}

.company-table th,
.company-table td {
  padding: 15px 8px;
  text-align: left;
  border-bottom: 1px solid var(--darkblue);
  border-right: 1px solid #010101;
  color: var(--gray);
  font-family: "Roboto", sans-serif;
}

.company-table tr:nth-child(even) {
  background-color: var(--lightblue);
}

.left-row-table{
  border-left: 1px solid #010101;
}

.company-table th {
  background-color: var(--darkblue);
  border: 1px solid var(--darkblue);
  border-right: 1px solid #010101;
  color: var(--white);
}

.company-table tbody tr:hover {
  background-color: #f5f5f5;
}

.company-table tbody tr:nth-child(even):hover {
  background-color: #afc9fc;
}

.kommune-table{
  text-transform: lowercase;
}

.kommune-table::first-letter{
  text-transform: uppercase;
}

.pagination {
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
}

.pagination li.active {
  background-color: #333;
  color: #fff;
}

